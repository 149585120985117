<template>
  <div>
    <header class="header-top">
      <aside>
        <el-select v-model="params.status" placeholder="请选择状态" @change="getList">
          <el-option :value="-1" label="全部"></el-option>
          <el-option :value="0" label="停用"></el-option>
          <el-option :value="1" label="启用"></el-option>
        </el-select>
      </aside>
      <el-button type="primary" @click="ctl('add')">添加</el-button>
    </header>
    <DragTable :header="header" :list-query="list" :sort="true" @change="sort">
      <template #image="{row}"><img class="site-img" :src="row.image" /></template>
      <template #updateTime="{row}">{{row.updated_at && $dayjs(row.updated_at*1000).format('YYYY-MM-DD HH:mm:ss') || ''}}</template>
      <template #status="{row}">{{row.status === 0?'停用':'启用'}}</template>
      <template #control="{row}">
        <div class="control">
          <span v-if="row.status == 0" class="word-color red" @click="ctl('del', row)">删除</span>
          <span class="word-color" v-if="row.status == 1" @click="ctl('stop', row)">停用</span>
          <span class="word-color" v-else @click="ctl('play', row)">启用</span>
        </div>
      </template>
    </DragTable>
    <go-dialog v-model="visible">
      <h2>添加站点分类</h2>
      <section class="flex-item">
        <span>站点分类名称：</span>
        <el-input v-model="categoryInfo.name"></el-input>
      </section>
      <footer class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'

export default defineComponent({
  setup(props,{ root }) {
    const visible = ref(false)
    const params = reactive({
      status: -1,
      is_show_page: 0
    })
    const categoryInfo = reactive({
      name: ''
    })
    const list = reactive({data: []})
    const header = [
      { name: "序号", value: "eq" },
      { name: "站点分类名称", value: "tag_name" },
      { name: "导航展示", slot: "status" },
      { name: "更新时间", slot: "updateTime" },
      { name: "操作人", value: "admin_name" },
      { name: "操作", slot: 'control', width: 100 },
    ]
    watch(visible,val=>{
      if(!val) {
        getList()
      }
    })
    const getList = () => {
      root.$axios.get(`/category/siteTag/index`,{
        params
      })
      .then(res=>{
        res.data.result.forEach((item,i)=>{
          item.eq= i+1
        })
        list.data = res.data.result
      })
      
    }
    getList()
    const ctl = async (type,data) => {
      let url = '/category/siteTag/update'
      let str = ''
      let num = 0
      switch(type) {
        case 'add':
          visible.value = true
        break
        case 'del':
          url = '/category/siteTag/delete'
          str = '确定要删除吗？'
        break
        case 'stop':
          str = '确定要停止吗？'
          num = 0
        break
        case 'play':
          str = '确定要启用吗？'
          num = 1
        break
      }
      if(type == 'add') return
      const confirm = await root.$goDialog(str)
      if(!confirm) return
      root.$axios.post(url,{
        id: data.id,
        status: num
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
      
    }
    const cancel = () => {
      visible.value = false
      categoryInfo.name = ''
    }
    const confirm = () => {
      root.$axios.post(`/category/siteTag/store`, categoryInfo)
      .then(res=>{
        root.$message.success(res.msg)
        getList()
        cancel()
      })
      
    }
    const sort = async (data) => {
      try {
        const arr = []
        data.forEach((item,i) => {
          arr.push({ id: item.id, sort: i+1 })
        });
        await root.$axios.post('/category/siteTag/sort',{
          sort_list: arr
        })
      } catch (error) {
        getList()
        root.$message.error(error)
      }
    }
    return {
      visible,
      categoryInfo,
      params,
      header,
      list,
      sort,
      getList,
      ctl,
      confirm,
      cancel
    }
  },
})
</script>
<style lang="scss" scoped>
.control{
  span{
    margin-right: 15px;
  }
}
.dialog-footer{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
</style>