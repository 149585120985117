<template>
  <el-tabs v-model="active">
      <el-tab-pane label="站点分类配置" name="1">
        <first v-if="active == 1"></first>
      </el-tab-pane>
      <el-tab-pane label="站点导航配置" name="2">
        <second v-if="active == 2"></second>
      </el-tab-pane>
    </el-tabs>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import first from './category.vue'
import second from './navitor.vue'

export default defineComponent({
  components: { first, second },
  setup() {
    const active = ref('1')

    return{
      active
    }
  },
})
</script>
<style lang="scss" scoped>

</style>