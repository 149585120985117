var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"header-top"},[_c('aside',[_c('el-select',{attrs:{"placeholder":"请选择状态"},on:{"change":_vm.getList},model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}},[_c('el-option',{attrs:{"value":-1,"label":"全部"}}),_c('el-option',{attrs:{"value":0,"label":"停用"}}),_c('el-option',{attrs:{"value":1,"label":"启用"}})],1)],1),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.ctl('add')}}},[_vm._v("添加")])],1),_c('DragTable',{attrs:{"header":_vm.header,"list-query":_vm.list,"sort":true},on:{"change":_vm.sort},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var row = ref.row;
return [_c('img',{staticClass:"site-img",attrs:{"src":row.image}})]}},{key:"updateTime",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.updated_at && _vm.$dayjs(row.updated_at*1000).format('YYYY-MM-DD HH:mm:ss') || ''))]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.status === 0?'停用':'启用'))]}},{key:"control",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"control"},[(row.status == 0)?_c('span',{staticClass:"word-color red",on:{"click":function($event){return _vm.ctl('del', row)}}},[_vm._v("删除")]):_vm._e(),(row.status == 1)?_c('span',{staticClass:"word-color",on:{"click":function($event){return _vm.ctl('stop', row)}}},[_vm._v("停用")]):_c('span',{staticClass:"word-color",on:{"click":function($event){return _vm.ctl('play', row)}}},[_vm._v("启用")])])]}}])}),_c('go-dialog',{model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('h2',[_vm._v("添加站点分类")]),_c('section',{staticClass:"flex-item"},[_c('span',[_vm._v("站点分类名称：")]),_c('el-input',{model:{value:(_vm.categoryInfo.name),callback:function ($$v) {_vm.$set(_vm.categoryInfo, "name", $$v)},expression:"categoryInfo.name"}})],1),_c('footer',{staticClass:"dialog-footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }