<template>
  <div>
    <header class="header-top">
      <aside>
        <el-input style="margin-bottom:15px;" v-model="params.keyword" clearable placeholder="请输入站点名称&链接" @keyup.native.enter="getList"></el-input>
        <el-select v-model="params.site_tag_id" placeholder="站点分类">
          <el-option v-for="item in siteList" :key="item.id" :value="item.id" :label="item.tag_name"></el-option>
        </el-select>
        <el-select v-model="params.status" placeholder="请选择状态">
          <el-option :value="-1" label="全部"></el-option>
          <el-option :value="0" label="停用"></el-option>
          <el-option :value="1" label="启用"></el-option>
        </el-select>
        <el-button type="primary" @click="getList">查询</el-button>
        <el-button type="primary" @click="reset">重置</el-button>
      </aside>
    </header>
    <DragTable :header="header" :list-query="list">
      <template #image="{row}"><img class="site-img" :src="row.img_url" /></template>
      <template #updateTime="{row}">{{row.relation_updated_at && $dayjs(row.relation_updated_at*1000).format('YYYY-MM-DD HH:mm:ss') || ''}}</template>
      <template #status="{row}">{{row.relation_status === 0?'停用':'启用'}}</template>
      <template #control="{row}">
        <div class="control">
          <span v-if="row.relation_status == 0" class="word-color" @click="ctl('edit', row)">编辑</span>
          <span class="word-color" v-if="row.relation_status == 1" @click="ctl('stop', row)">停用</span>
          <span class="word-color" v-else @click="ctl('play', row)">启用</span>
        </div>
      </template>
    </DragTable>
    <footer class="table-footer">
      <p></p>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer>
    <edit :info="categoryInfo"></edit>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import edit from './component/edit.vue'

export default defineComponent({
  components: { edit },
  setup(props,{ root }) {
    const siteList = ref([])
    const total = ref(0)
    const params = reactive({
      status: -1,
      site_tag_id: '',
      is_show_page: 1,
      page: 1,
      per_page: 10,
      keyword: ''
    })
    const categoryInfo = reactive({
      id: '',
      visible: false
    })
    const list = reactive({data: []})
    const header = [
      { name: "站点ID", value: "site_id" },
      { name: "站点分类名称", value: "tag_name" },
      { name: "站点名称", value: "site_name" },
      { name: "图片", slot: "image" },
      { name: "导航展示", slot: "status" },
      { name: "权重", value: "relation_sort" },
      { name: "更新时间", slot: "updateTime" },
      { name: "操作人", value: "relation_admin_name" },
      { name: "操作", slot: 'control', width: 100 },
    ]
    watch(categoryInfo,val=>{
      if(!val.visible) {
        getList()
      }
    })
    const getList = () => {
      root.$axios.get(`/category/navigation/index`,{
        params
      })
      .then(res=>{
        list.data = res.data.result
        total.value = res.data.pagination.total
      })
      
    }
    getList()
    const getSite = () => {
      root.$axios.get(`/category/siteTag/index`,{
        params: {
          status: 1
        }
      })
      .then(res=>{
        siteList.value = res.data.result
      })
      
    }
    getSite()
    const ctl = async (type,data) => {
      let url = '/category/navigation/update'
      let str = ''
      let num = 0
      switch(type) {
        case 'edit':
          categoryInfo.visible = true
          Object.assign(categoryInfo,data)
        break
        case 'stop':
          str = '确定要停止吗？'
          num = 0
        break
        case 'play':
          str = '确定要启用吗？'
          num = 1
        break
      }
      if(type == 'edit') return
      const confirm = await root.$goDialog(str)
      if(!confirm) return
      root.$axios.post(url,{
        site_id: data.site_id,
        relation_status: num
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
      
    }
    const reset = ()=>{
      Object.assign(params,{
        status: -1,
        site_tag_id: '',
        is_show_page: 1,
        page: 1,
        per_page: 10,
        keyword: ''
      })
      getList()
    }
    const handleCurrentChange = e => {
      params.page = e
      getList()
    }
    const handleSizeChange = e => {
      params.per_page = e
      params.page = 1
      getList()
    }
    return {
      total,
      categoryInfo,
      params,
      header,
      list,
      siteList,
      reset,
      getList,
      ctl,
      handleSizeChange,
      handleCurrentChange
    }
  },
})
</script>
<style lang="scss" scoped>
.control{
  span{
    margin-right: 15px;
  }
}
.dialog-footer{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.site-img{
  max-height: 100px;
  object-fit: contain;
}
</style>