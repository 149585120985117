<template>
  <go-dialog v-model="visible" width="700px" v-loading="loading">
    <div class="contain">
      <section>
        <div class="flex-item">
          <aside>站点ID：</aside>
          <el-input v-model="siteInfo.site_id" disabled></el-input>
        </div>
        <div class="flex-item">
          <aside>站点名称：</aside>
          <el-input v-model="siteInfo.site_name" placeholder="请输入站点名称"></el-input>
        </div>
        <div class="flex-item">
          <aside>站点链接：</aside>
          <el-input v-model="siteInfo.site_host" placeholder="请输入站点链接"></el-input>
        </div>
        <div class="flex-item">
          <aside>站点分类：</aside>
          <el-select v-model="siteInfo.tag_id">
            <el-option v-for="item in siteList" :key="item.id" :value="item.id" :label="item.tag_name"></el-option>
          </el-select>
        </div>
        <div class="flex-item">
          <aside>导航展示：</aside>
          <el-select v-model="siteInfo.relation_status">
            <el-option :value="0" label="停用"></el-option>
            <el-option :value="1" label="启用"></el-option>
          </el-select>
        </div>
        <div class="flex-item">
          <aside>权重：</aside>
          <el-input v-model="siteInfo.relation_sort" type="number" placeholder="请输入权重"></el-input>
        </div>
      </section>
      <section>
        <upload :info="imgInfo" class="upload-img" @getimage="getimage" />
      </section>
    </div>
    <footer class="table-footer">
      <span></span>
      <div>
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="save">确定</el-button>
      </div>
    </footer>
  </go-dialog>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import Upload from "@/components/common/Upload";

export default defineComponent({
  components: { Upload },
  props:['show','info'],
  setup(props,{ root }) {
    const loading = ref(false)
    const visible = ref(false)
    const siteList = ref([])
    const imgInfo = reactive({
      url: '',
      width: 400,
      height: 400
    })
    const siteInfo = reactive({
      site_id: '',
      site_name: '',
      site_host: '',
      site_img_url: '',
      tag_id: '',
      relation_status: '',
      relation_sort: ''
    })
    watch(props.info,data=>{
      Object.assign(siteInfo,data)
      if(data.visible) {
        loading.value = true
        getSite()
        getInfo()
      }
      visible.value = data.visible
    })
    const getSite = () => {
      root.$axios.get(`/category/siteTag/index`,{
        params: {
          status: 1
        }
      })
      .then(res=>{
        siteList.value = res.data.result
      })
    }
    const getInfo = () => {
      try{
        root.$axios.get(`/category/navigation/show`, {
          params: {
            site_id: props.info.site_id
          }
        })
        .then(res=>{
          imgInfo.url = res.data.site_img_url
          Object.assign(siteInfo,res.data)
        })
      } catch(err){
        root.$message.error(err)
      } finally{
        loading.value = false
      }
    }
    const getimage = (e) => {
      siteInfo.site_img_url = '//'+e
    };
    const cancel = () => {
      for(let key in siteInfo) {
        siteInfo[key] = ''
      }
      props.info.visible = false
    }
    const save = () => {
      if(!siteInfo.site_name || !siteInfo.site_name.trim()) {
        root.$message.warning('请输入站点名称')
        return
      }
      if(!siteInfo.site_host && !siteInfo.site_host.trim()) {
        root.$message.warning('请输入站点链接')
        return
      }
      if(!siteInfo.relation_sort && siteInfo.relation_sort != 0) {
        root.$message.warning('请设置权重')
        return
      }
      root.$axios.post(`/category/navigation/update`, siteInfo)
      .then(res=>{
        root.$message.success(res.msg)
        cancel()
      })
      
    }
    return {
      loading,
      visible,
      siteInfo,
      siteList,
      imgInfo,
      getimage,
      cancel,
      save
    }
  },
})
</script>
<style lang="scss" scoped>
.contain{
  display: flex;
  &>section{
    width: 50%;
    padding-right: 30px;
  }
}
.flex-item{
  aside{
    width: 100px;
  }
}
</style>